<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          class="sosty-logo-header"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            class="sosty-login-image"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a Sosty! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Por favor, crea una cuenta con tu email y contraseña
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    name="login-email"
                    placeholder="john@example.com"
                  />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                </div>
                <b-input-group
                    class="input-group-merge"                    
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"                      
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
              </b-form-group>

              <!-- Nombres -->
              <b-form-group
                label="Nombre(s)"
                label-for="login-first-name"
              >
                <b-form-input
                    id="login-first-name"
                    v-model="userFirstName"
                    name="login-first-name"
                    placeholder="John"
                  />
              </b-form-group>

              <b-form-group
                label="Apellido(s)"
                label-for="login-last-name"
              >
                <b-form-input
                    id="login-last-name"
                    v-model="userLastName"
                    name="login-last-name"
                    placeholder="Smith"
                  />
              </b-form-group>

              <b-form-group
                label="Celular"
                label-for="login-phone"
              >
                <b-form-input
                    id="login-phone"
                    v-model="userPhoneNumber"
                    name="login-phone"
                    placeholder="314 376 1024"
                  />
              </b-form-group>

              <b-form-group v-if="showUserTypeSelection">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="userType"
                    plain
                    name="some-radios3"
                    value="Inversionista"
                  >
                    Soy NeoGanadero - Quiero Invertir Dinero
                  </b-form-radio>
                  <!-- <b-form-radio
                    v-model="userType"
                    plain
                    name="some-radios3"
                    value="Productor"
                  >
                    Soy Productor - Tengo Finca y Necesito Inversión
                  </b-form-radio> -->
                  <b-form-radio
                    v-model="userType"
                    plain
                    name="some-radios3"
                    value="UsuarioSubasta"
                  >
                    Quiero Vender Ganado
                  </b-form-radio>                
                </div>                  
              </b-form-group>
              

              <!-- checkbox -->              
              <b-form-group class="mt-3">
                <b-form-checkbox
                  id="accept-privacy-policy-and-terms-and-conditions"
                  v-model="acceptPrivaciPolicyAndTermsAndConditions"
                  name="checkbox-1"
                >
                  Acepto 
                  <a href="https://sosty.co/politica-de-privacidad/" target="_blank">
                    <span>&nbsp;Política de Privacidad</span>
                  </a> y 
                  <a href="https://sosty.co/terminos-y-condiciones/" target="_blank">
                    <span>&nbsp;Términos y Condiciones</span>
                  </a>

                </b-form-checkbox>
              </b-form-group>

              <!-- <b-form-group>
                <b-form-checkbox
                  id="accept-receive-comunications"
                  v-model="acceptReceiveComunications"
                  name="checkbox-1"
                >
                  Autorizo recibir comunicaciones
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->             

              <b-button class="mt-3" v-if="!processing" type="submit" variant="primary" block @click="register">
                Registrarme
              </b-button>

              <b-button v-if="processing" type="submit" variant="primary" disabled block>
                 <b-spinner small label="Cargando..." />
              </b-button>

                <b-row v-if="this.checkStatus && !processing" class="mt-1">      
                  <b-col md="12" lg="12">              
                      <b-alert variant="warning" show>
                        <div class="alert-body">
                          <span><strong>Debes confirmar tu cuenta a través de tu correo electrónico: {{ userEmail }}</strong></span>
                        </div>
                      </b-alert>
                  </b-col>
              </b-row>  

            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Ya tienes una cuenta? </span>
            <a style="color:#008D36" class="mt-2" variant="outline-primary" block @click="goToLoginWithRedirection">              
              <span class="align-middle">entra</span>
            </a>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              o usa
            </div>
          </div> -->

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <!-- <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button> -->
            <!-- <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button> -->
            <!-- <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button> -->
            <!-- <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button> -->
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormRadio, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButtonGroup, BButton, BSpinner,BAlert,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormRadio,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButtonGroup,
    BButton,  
    BSpinner,  
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      userType: 'Inversionista',
      userFirstName: '',
      userLastName: '',
      userPhoneNumber: '',
      acceptPrivaciPolicyAndTermsAndConditions: false,
      // acceptPrivaciPolicy: false,
      processing:false,
      showUserTypeSelection: true,
      sideImg: require('@/assets/images/pages/sosty-login.png'),
      redirectToProjectCode: null,
      checkStatus: false,
      origin: 'register'
    }
  },
  created() {
    this.checkStatus = false
    if(this.$route != null && this.$route.query != null && this.$route.query.userType != null) {
      this.userType = this.$route.query.userType;
      this.showUserTypeSelection = false;
    } else {
      this.showUserTypeSelection = true;
    }

    if(this.$route != null && this.$route.query != null && this.$route.query.redirectToProjectCode != null) {
      this.redirectToProjectCode = this.$route.query.redirectToProjectCode;
    } 

    if(this.$route != null && this.$route.query != null && this.$route.query.origin != null) {
      this.origin = this.$route.query.origin;
    }
  },
  watch:{
    checkStatus: function(val) { this.checkStatus = val; },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    register() {
        if(this.acceptPrivaciPolicyAndTermsAndConditions == true){
          this.processing = true;
          useJwt.register({
            email: this.userEmail,
            password: this.password,
            userType: this.userType,
            firstName: this.userFirstName,
            lastName: this.userLastName,
            phoneNumber: this.userPhoneNumber,
            origin: 'register',
          })
          .then(response => {  
            this.processing = false;      
            useJwt.setToken(response.data.accessToken)
            var abilities = getAbilitiesBasedOnUserType(response.data.user.userType);
            response.data.user.ability = abilities;
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            this.$ability.update(abilities);            
            this.checkStatus = true    
            if(this.redirectToProjectCode){
              this.$router.replace({path: 'public-project-details?projectCode=' + this.redirectToProjectCode});
            }else{
              this.$router.replace(getHomeRouteForLoggedInUser(response.data.user.userType));
            }
          })
          .catch(error => {
            this.processing = false;
            console.log(error);
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
          })
        } else {
          this.$bvToast.toast('Debes aceptar la politica de privacidad y los temrinos y condiciones.', { title: 'Error', variant: 'danger', solid: true, })
        }    
    },
    goToLoginWithRedirection() {
      if(this.redirectToProjectCode){
        this.$router.replace({path: 'login?redirectToProjectCode=' + this.redirectToProjectCode});
      }else{
        this.$router.replace({path: 'login'});
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

